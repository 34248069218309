import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import { rxAiTemplate } from '../rxState';

export interface AiTemplateQuestion {
  id: string;
  label: string;
  example: string;
}

export interface AiPrompts {
  id: string;
  type: string;
  prompt: string;
}

export interface AiTemplate {
  questions: AiTemplateQuestion[];
  prompts: AiPrompts[][];
  promptsFlat: AiPrompts[];
}

export const loadAiTemplateData = async (pageId: string) => {
  if (pageId) {
    const data = await graphQlCall({
      queryTemplateObject: QUERIES.GET_ONE_AI_PAGE,
      values: {
        pageId,
      },
      headerType: 'USER-AUTH',
    });

    let promptsFlat: AiPrompts[] = [];
    data.prompts.forEach(
      (prompt: AiPrompts[]) => (promptsFlat = promptsFlat.concat(prompt))
    );
    let aiTemplate: AiTemplate = {
      questions: data.questions,
      prompts: data.prompts,
      promptsFlat: promptsFlat,
    };
    rxAiTemplate.next(aiTemplate);
  }
};

export const findAllFields = (prompt: string) => {
  let matchArr: RegExpExecArray | null,
    matches: any = [];
  const exp = /(#)([0-9A-Za-z]*)/g;
  while ((matchArr = exp.exec(prompt)) !== null) {
    matches.push(matchArr[2]);
  }
  return matches;
};

const sequncePromptsForLayers = (
  questions: AiTemplateQuestion[],
  prompts: AiPrompts[]
) => {
  let avialableFields: string[] = [];
  for (const question of questions) {
    avialableFields.push(question.id);
  }
  let out: AiPrompts[][] = [];

  let promptsToProcess = [...prompts];
  let index = 0;
  while (promptsToProcess.length !== 0) {
    index++;
    let promptLayer: AiPrompts[] = [];
    for (const prompt of promptsToProcess) {
      if (prompt.id === '') {
        console.log('skipping empty prompt');
        continue;
      }
      const fields = findAllFields(prompt.prompt);
      let dependenciesSatisfied = true;
      for (const field of fields) {
        if (!avialableFields.includes(field)) {
          dependenciesSatisfied = false;
          break;
        }
      }
      if (dependenciesSatisfied) {
        promptLayer.push(prompt);
      }
    }
    if (promptLayer.length == 0) {
      console.error(
        'ERROR:',
        'prompt layer is empty. dependencies can not be satisfied'
      );
      break;
    }

    promptsToProcess = promptsToProcess.filter(
      (prompt) => !promptLayer.includes(prompt)
    );
    out.push(promptLayer);
    promptLayer.forEach((prompt) => avialableFields.push(prompt.id));
  }

  return out;
};

export const saveAiTemplateData = async (pageId: string) => {
  let template = rxAiTemplate.getValue();
  if (template.questions === undefined) {
    return;
  }

  template.prompts = sequncePromptsForLayers(
    template.questions,
    template.promptsFlat
  );

  const data = JSON.stringify(template);
  const responeData = await graphQlCall({
    queryTemplateObject: QUERIES.UPDATE_AI_PAGE,
    values: {
      pageId,
      data,
    },
    headerType: 'USER-AUTH',
  });
};
