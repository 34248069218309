export const GET_TEMPLATE = {
  operationName: 'getTemplate',
  query: `query getTemplate ($id: String!){
          getTemplate (id: $id) {
            _id
            name
            description
            category
            createdAt
            updatedAt
            enabled
            pages {
                _id
                name
                enabled
                data
                image
            }
        }
    }`,
};
