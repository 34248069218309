import React from 'react';
import RcTooltip from 'rc-tooltip';
import './bootstrap.css';

export type TooltipPlacement =
  | 'top'
  | 'left'
  | 'right'
  | 'bottom'
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight'
  | 'leftTop'
  | 'leftBottom'
  | 'rightTop'
  | 'rightBottom';

type TooltipProps = {
  children: JSX.Element;
  overlayContent: React.ReactNode;
  placement?: TooltipPlacement;
  visible?: boolean;
  showArrow?: boolean;
  overlayClassName?: string;
};

const Tooltip = ({
  children,
  overlayContent,
  placement,
  visible,
  showArrow = true,
  overlayClassName,
}: TooltipProps) => {
  const popupPlacement = placement ? placement : 'bottom';

  return (
    <RcTooltip
      placement={popupPlacement}
      visible={visible}
      motion={{
        motionName: 'rc-tooltip-zoom',
        motionDeadline: 1000,
      }}
      showArrow={showArrow}
      overlay={overlayContent}
      overlayClassName={overlayClassName}
    >
      {children}
    </RcTooltip>
  );
};

export default Tooltip;
