import { ReactComponent as Attention } from 'Assets/Attention.svg';
import { rxIsLoading } from 'rx/rxState';
import { getDomain } from 'utils/functions';
import Popup from 'UILib/Popup/Popup';
import Button from 'UILib/Button/Button';

import styles from './LeavePopup.module.scss';

interface LeavePopupProps {
  projectId: string;
  onSave: () => void;
  onClose: () => void;
  isTemplate?: boolean;
}

export function LeavePopup(props: LeavePopupProps) {
  const { domain } = getDomain();
  const consultLink = `//${domain}/console/${
    props?.isTemplate
      ? 'funnel-templates'
      : `projects/${props.projectId}/refresh`
  }`;

  const handleSave = () => {
    rxIsLoading.next('Saving page...');
    props.onSave();
    setTimeout(() => {
      window.location.href = consultLink;
    }, 1500);
  };

  const handleLeave = () => {
    window.location.href = consultLink;
  };

  return (
    <Popup onClose={props.onClose} wrapperClassName={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.attention}>
            <Attention />
            <div className={styles.title}>
              Wait! You haven't saved your work!
            </div>
            <p className={styles.subtitle}>Would you like to save this page?</p>
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button onClick={handleLeave} appearance="stroke" width={180}>
            Leave without saving
          </Button>
          <Button onClick={handleSave} width={180}>
            Save
          </Button>
        </div>
      </div>
    </Popup>
  );
}
