import LoadingSpinner from 'Components/Common/LoadingSpinner/LoadingSpinner';
import { useState, useRef, useEffect, MutableRefObject, useMemo } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import Button from 'Components/Common/Button/Button';
import DropdownBox from 'Components/Common/dropdownBox/DropdownBox';
import { IUserDetails } from 'types/user';
import { useObservable } from 'utils/UseObservable';
import { eventEmitter, rxCurrentUser, rxUpdatePlanPopup } from 'rx/rxState';
import { ReactComponent as ChapterDelete } from 'Assets/chapterDelete.svg';
import { ReactComponent as AddNewChapter } from 'Assets/addNewChapter.svg';
import { ReactComponent as ChapterAdd } from 'Assets/chapterAdd.svg';
import { ReactComponent as Premium } from 'Assets/Premium.svg';
import { IBookChapter } from '../types';
import ChapterItem from '../ChapterItem/ChapterItem';
import ContentEditableDiv from '../ContentEditableDiv/ContentEditableDiv';
import ChapterPlaceholders from '../ChapterPlaceholders/ChapterPlaceholders';

import s from './BookInfoBlock.module.scss';

const SMALL_BOOK_PRICE = 1;
const MEDIUM_BOOK_PRICE = 2;
const LARGE_BOOK_PRICE = 3;

interface IProps {
  bookTitle: string;
  onBookTitleEdited: (value: string) => void;
  onChapterTitleEdited: (value: string, index: number) => void;
  chapters: IBookChapter[];
  loadingChapter: boolean;
  loadingCover: boolean;
  loadingAddChapter: boolean;
  deleteChapter: (index: number) => void;
  onChapterAdd: (indexBefore: number, action: 'add' | 'insert') => void;
  reorderChapter: (dragIndex: number, dropIndex: number) => void;
  onNoteAdd: (chapterIndex: number, noteText: string) => void;
  onGenerateBook: (value: string) => void;
  // setBookSize: (value: string) => void;
}

function BookInfoBlock({
  bookTitle,
  onBookTitleEdited,
  chapters,
  loadingChapter,
  loadingCover,
  onChapterTitleEdited,
  deleteChapter,
  onChapterAdd,
  reorderChapter,
  loadingAddChapter,
  onNoteAdd,
  onGenerateBook,
}: // setBookSize,
IProps) {
  const [bookTitleInput, setBookTitleInput] = useState<string>(bookTitle);
  const [activeChapter, setActiveChapter] = useState<number>(0);
  const [isBookTitleActive, setIsBookTitleActive] = useState<boolean>(false);
  const [isBookTitleLongError, setIsBookTitleLongError] =
    useState<boolean>(false);

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [lastSortingTimestamp, setLastSortingTimestamp] = useState<
    number | null
  >(null);

  const [menuItemRef, setMenuItemRef] =
    useState<MutableRefObject<HTMLDivElement | null> | null>(null);
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(
    null
  );

  const currentUser = useObservable<IUserDetails>(rxCurrentUser);

  const userData = useMemo(() => {
    const scopes = currentUser?.scopes ?? [];
    const legacyAccessMode = true //!scopes.includes('BOOKLE_GENERAL_ACCESS') || false;
    const aiCredits = currentUser?.aiCredits ?? 0;

    return {
      scopes,
      legacyAccessMode,
      aiCredits,
    };
  }, [currentUser]);

  const toggleDropdown = (
    index: number,
    ref: MutableRefObject<HTMLDivElement | null>
  ) => {
    // if (openDropdownIndex === index) {
    // setOpenDropdownIndex(null);
    // } else {
    setOpenDropdownIndex(index);
    // }
    setMenuItemRef(ref);
  };

  // const [chapterItemDropdownOpen, setChapterItemDropdownOpen] = useState(false);

  useEffect(() => {
    setBookTitleInput(bookTitle);
  }, [bookTitle]);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      const currentTime = Date.now();
      if (lastSortingTimestamp && currentTime - lastSortingTimestamp > 1000) {
        //endReorderChapter();
      }
    }, 1000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [lastSortingTimestamp]);

  useEffect(() => {
    const subscription = rxUpdatePlanPopup.subscribe((data) => {
      if (data?.open === false) {
        eventEmitter.next({ type: 'fetch-current-user' });

        //fetch another user scop in 1 second later to get updated credits. it takes time for stripe to process credits.
        setTimeout(() => {
          eventEmitter.next({ type: 'fetch-current-user' });
        }, 1000);
      }
    });

    return () => {
      subscription.unsubscribe();
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const handleChapterAdding = (
    indexBefore: number,
    action: 'add' | 'insert'
  ) => {
    if (
      !userData.scopes.includes('BOOKLE_ADD_NEW_CHAPTER') &&
      !userData.legacyAccessMode
    ) {
      showUpgradePlan('Adding new chapters IS not available in your plan');
      return;
    }
    onChapterAdd(indexBefore, action);
  };

  const handleGenerateBook = (size: string) => {
    if (loadingCover) {
      return;
    }
    if (userData.legacyAccessMode) {
      onGenerateBook(size);
      return;
    }

    if (size === 'MEDIUM') {
      if (!userData.scopes.includes('BOOKLE_MEDIUM_BOOK')) {
        showUpgradePlan('Medium books IS not available in your plan');
        return;
      }
      if (userData.aiCredits < MEDIUM_BOOK_PRICE) {
        showUpgradePlan('Not enough credits to generate medium book');
        return;
      }
    }
    if (size === 'LARGE') {
      if (!userData.scopes.includes('BOOKLE_LARGE_BOOK')) {
        showUpgradePlan('Large books IS not available in your plan');
        return;
      }
      if (userData.aiCredits < LARGE_BOOK_PRICE) {
        showUpgradePlan('Not enough credits to generate large book');
        return;
      }
    }

    if (size === 'SMALL' && userData.aiCredits < SMALL_BOOK_PRICE) {
      showUpgradePlan('Not enough credits to generate small book');
      return;
    }

    onGenerateBook(size);
  };

  const showUpgradePlan = (reason: string) => {
    rxUpdatePlanPopup.next({ open: true, message: reason });
  };

  const handleNameInput = (value: string) => {
    let textValue = value;
    if (textValue.length > 150) {
      textValue = textValue.slice(0, 150);
      setIsBookTitleLongError(true);
    } else {
      setIsBookTitleLongError(false);
    }
    const words = textValue.split(' ');
    const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
        return word[0].toUpperCase() + word.slice(1);
      }
      return word;
    });
    const capitalizedValue = capitalizedWords.join(' ');

    setBookTitleInput(capitalizedValue);
  };

  const handleChapterSorting = (res: DropResult) => {
    const { destination, source, draggableId } = res;

    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    setLastSortingTimestamp(Date.now());
    reorderChapter(source.index, destination.index);
  };

  const isAddChaptersLocked = () => {
    return (
      !userData.scopes.includes('BOOKLE_ADD_NEW_CHAPTER') &&
      !userData.legacyAccessMode
    );
  };

  const chapterItemDropDownMenu = () => {
    return (
      <DropdownBox
        isOpen={openDropdownIndex !== null}
        onClose={() => setOpenDropdownIndex(null)}
        pointerEvents={true}
        componentRef={menuItemRef}
        height={70}
      >
        <div className={s.dotsMenuBlock}>
          <>
            <div
              className={loadingAddChapter ? s.cursorDisabled : ''}
              onClick={
                loadingAddChapter
                  ? () => {}
                  : () => {
                      onChapterAdd(openDropdownIndex!, 'insert');
                      setOpenDropdownIndex(null);
                    }
              }
            >
              <ChapterAdd /> {isAddChaptersLocked() ? <Premium /> : null} Add
              New Above
            </div>
            <div
              className={loadingAddChapter ? s.cursorDisabled : ''}
              onClick={
                loadingAddChapter
                  ? () => {}
                  : () => {
                      onChapterAdd(openDropdownIndex! + 1, 'insert');
                      setOpenDropdownIndex(null);
                    }
              }
            >
              <ChapterAdd /> {isAddChaptersLocked() ? <Premium /> : null} Add
              New Below
            </div>
          </>
          <div
            className={loadingAddChapter ? s.cursorDisabled : ''}
            onClick={
              loadingAddChapter
                ? () => {}
                : () => {
                    deleteChapter(openDropdownIndex!);
                    setOpenDropdownIndex(null);
                  }
            }
          >
            <ChapterDelete /> Delete
          </div>
        </div>
      </DropdownBox>
    );
  };

  return (
    <div className={s.bookInfoContainer}>
      <div className={s.bookRelativeInfoContainer}>
        <DragDropContext onDragEnd={handleChapterSorting}>
          <div className={s.bookInfoEditNameInput}>
            <div className={s.bookInfoEditNameInputHeader}>Your Book Title</div>
            <div className={s.bookInfoEditNameInputInner}>
              <ContentEditableDiv
                name={bookTitleInput}
                fontSize={30}
                lineHeight={42}
                fontWeight={600}
                // width={null}
                height={37}
                onChange={handleNameInput}
                onEdit={onBookTitleEdited}
              />
              {isBookTitleLongError && (
                <div className={s.errorText}>Maximum characters 150</div>
              )}
            </div>
          </div>
          <Droppable droppableId="chapters">
            {(provided) => (
              <div
                className={s.chaptersBlock}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {loadingChapter ? (
                  <ChapterPlaceholders />
                ) : (
                  chapters.map((chapter: IBookChapter, index) => (
                    <Draggable
                      key={chapter.title + index.toString()}
                      draggableId={`chapter-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ChapterItem
                            index={index}
                            chapter={chapter}
                            onChapterTitleEditedProps={onChapterTitleEdited}
                            activeChapter={activeChapter}
                            setActiveChapter={setActiveChapter}
                            // deleteChapter={deleteChapter}
                            // onChapterAdd={handleChapterAdding}
                            // loadingAddChapter={loadingAddChapter}
                            onNoteAdd={onNoteAdd}
                            // isBookTitleActive={isBookTitleActive}
                            setIsBookTitleActive={setIsBookTitleActive}
                            toggleDropdown={toggleDropdown}
                            // isOpen={openDropdownIndex === index}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))
                )}
                {!loadingChapter && (
                  <div
                    className={s.addNewChapter}
                    onClick={() => handleChapterAdding(chapters.length, 'add')}
                  >
                    {loadingAddChapter ? (
                      <LoadingSpinner />
                    ) : (
                      <div style={{ position: 'relative', display: 'flex' }}>
                        {isAddChaptersLocked() ? (
                          <Premium className={s.premiumIconWithOffset} />
                        ) : null}
                        <AddNewChapter />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {openDropdownIndex !== null && chapterItemDropDownMenu()}
      </div>
      {!loadingChapter && (
        <>
          <div className={s.generateBookButtonsBlock}>
            {!userData.legacyAccessMode && (
              <div className={s.credits}>Credits: {userData.aiCredits}</div>
            )}

            <Button
              size="regular"
              color="accent"
              onClick={() => handleGenerateBook('SMALL')}
              borderRadius={'25px'}
            >
              <div className={s.generateBookButtonsContainer}>
                <div className={s.generateBookButtonsTextBlock}>
                  <span className={s.textSize}>Small</span>
                </div>
                {!userData.legacyAccessMode && (
                  <span className={s.textPrice}>1 Credit</span>
                )}
              </div>
            </Button>
            <Button
              size="regular"
              color="accent"
              onClick={() => handleGenerateBook('MEDIUM')}
              borderRadius={'25px'}
            >
              <div className={s.generateBookButtonsContainer}>
                <div className={s.generateBookButtonsTextBlock}>
                  {!userData.scopes.includes('BOOKLE_MEDIUM_BOOK') &&
                  !userData.legacyAccessMode ? (
                    <span>
                      <Premium className={s.buttonIcon} />
                    </span>
                  ) : null}
                  <span className={s.textSize}>Medium</span>
                </div>
                {!userData.legacyAccessMode && (
                  <span className={s.textPrice}>2 Credits</span>
                )}
              </div>
            </Button>
            <Button
              size="regular"
              color="accent"
              onClick={() => handleGenerateBook('LARGE')}
              borderRadius={'25px'}
            >
              <div className={s.generateBookButtonsContainer}>
                <div className={s.generateBookButtonsTextBlock}>
                  {!userData.scopes.includes('BOOKLE_LARGE_BOOK') &&
                  !userData.legacyAccessMode ? (
                    <span>
                      <Premium className={s.buttonIcon} />
                    </span>
                  ) : null}
                  <span className={s.textSize}>Large</span>
                </div>
                {!userData.legacyAccessMode && (
                  <span className={s.textPrice}>3 Credits</span>
                )}
              </div>
            </Button>
          </div>
          {loadingCover && (
            <div
              style={{
                textAlign: 'center',
                marginTop: '-20px',
                marginBottom: '10px',
              }}
            >
              Please wait for cover to arrive before starting generation.
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default BookInfoBlock;
