import { useEffect } from 'react';
import { getDomain } from 'utils/functions';
import { useObservable } from 'utils/UseObservable';
import { rxUpdatePlanPopup } from 'rx/rxState';

import s from './Popup.module.scss';

const UpdatePlanPopup = () => {
  const popupInfo = useObservable<{ open: boolean; message?: string }>(
    rxUpdatePlanPopup
  );

  useEffect(() => {
    window.addEventListener('message', (event) => {
      const data = event.data;
      if (data.status && data.status == 'close') {
        rxUpdatePlanPopup.next({ open: false });
      }
    });
  }, []);

  const { domain } = getDomain();
  const iframeSrc = `//${domain}/console/frame/update-plan?message=${encodeURIComponent(
    popupInfo?.message ?? ''
  )}`;

  if (!popupInfo?.open) return null;

  return (
    <div className={s.frameModalPayment}>
      <iframe
        src={iframeSrc}
        title="update-plan"
        width="100%"
        height="100%"
        className={s.frame}
        allowTransparency={true}
        style={{ backgroundColor: 'transparent' }}
      ></iframe>
    </div>
  );
};

export default UpdatePlanPopup;
