import { EventHandler } from 'react';
import { PAGECRAFT_API_URL } from 'Constants';
import { TOOLBAR_ID } from '../Components/Toolbar/ToolbarPanel';
import { getUserId } from './api';
import domtoimage from './dom-to-image';

export const isToolbarAction: EventHandler<any> = (e) => {
  const toolbar = document.getElementById(TOOLBAR_ID);
  return toolbar && toolbar.contains(e.target);
};

export const kebabize = (str: string) =>
  str.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($, ofs) => (ofs ? '-' : '') + $.toLowerCase()
  );

export const classnames = (...args: Array<string | void>): string =>
  args.filter(Boolean).join(' ');

export function randomElement<T = any>(array: T[]): T {
  return array[Math.floor(array.length * Math.random())];
}

export const resizeImageToWidth = (width: number, imageBase64: string) => {
  const promise: Promise<string> = new Promise((resolve, reject) => {
    const oc = document.createElement('canvas');
    const octx = oc.getContext('2d');
    if (octx) {
      const img = new Image();
      img.src = imageBase64;
      img.onload = () => {
        const w = width;
        const h = (img.height * width) / img.width;
        oc.width = w;
        oc.height = h;
        octx.drawImage(img, 0, 0, w, h);
        const dataUrl = oc.toDataURL('image/jpeg', 0.9);
        const outputBase64 = dataUrl.replace(/^.+,/, '');
        resolve(outputBase64);
      };
    } else {
      reject('2d context not found');
    }
  });

  return promise;
};

export const loadFile = (
  file: Blob | undefined,
  callback: (value: string | ArrayBuffer | null) => void
) => {
  if (!file) return;

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = (res) => {
    const content = reader.result;
    callback(content);
  };

  uploadFile(file, callback);
};

const uploadFile = (
  file: Blob,
  callback: (value: string | ArrayBuffer | null) => void
) => {
  const formData = new FormData();
  formData.append('field', JSON.stringify({ id: getUserId() }));
  formData.append('file', file);

  fetch(`${PAGECRAFT_API_URL}/images`, {
    method: 'POST',
    body: formData,
  })
    .then((r) => r.json())
    .then((data) => {
      callback(data.url);
    });
};

export const generateBookCoverThumbnail = (width: number) => {
  const promise: Promise<string | null> = new Promise((resolve, reject) => {
    const coverElement = document.getElementById('CoverEditor');
    if (coverElement) {
      const rect = coverElement.getBoundingClientRect();
      domtoimage
        .toJpeg(coverElement!, {
          width: rect.width,
          height: rect.height,
          filter: (node: Node) => {
            const className = (node as HTMLElement).className;
            if (className && className.search('Manipulator') >= 0) {
              //ignore all Manipulator elements
              return false;
            }
            return true;
          },
        })
        .then((imageBase64) => {
          if (rect.width !== width) {
            resizeImageToWidth(width, imageBase64).then((resultBase64) => {
              resolve(resultBase64);
            });
          } else {
            resolve(imageBase64.replace(/^.+,/, ''));
          }
        });
    } else {
      console.log('warning: CoverEditor tag is not found');
      resolve(null);
    }
  });
  return promise;
};

export const b64toBlob = (
  b64Data: string,
  contentType = '',
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const getLinkWithoutCDN = (link: string) => {
  if (link.includes('https://cdn.autofunnel.ai')) {
    return link.replace(
      'https://cdn.autofunnel.ai',
      'https://s3.us-east-2.amazonaws.com/cdn.autofunnel.ai'
    );
  }
  return link;
};

export const getDomain = () => {
  const url = window.location.href;
  const hostname = new URL(url).hostname;
  const isAutoFunnel =
    hostname === 'localhost' ||
    hostname === 'app.dev.autofunnel.ai' ||
    hostname === 'app.autofunnel.ai';

  return {
    domain: hostname === 'localhost' ? 'localhost:3000' : hostname,
    isAutoFunnel,
  };
};
