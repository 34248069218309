import { graphQlCall } from 'graphql/utils';
import {
  rxBlocks,
  rxExitModalData,
  rxExitModalEnabled,
  rxInitialBlocks,
  rxIsLoading,
  rxTemplateData,
  rxTemplateId,
} from 'rx/rxState';
import queries from 'graphql/queries';

export const fetchTemplateData = async (id: string) => {
  try {
    rxIsLoading.next('Loading...');

    if (id) {
      const data = await graphQlCall({
        queryTemplateObject: queries.GET_TEMPLATE,
        values: {
          id,
        },
        headerType: 'USER-AUTH',
      });

      if (data._id) {
        rxTemplateId.next(data._id);

        const templateData = {
          name: data.name,
          description: data.description,
          category: data.category,
        };
        rxTemplateData.next(templateData);

        const pageData = data.pages[0];
        if (pageData.exitpopupenabled) {
          rxExitModalEnabled.next(pageData.exitpopupenabled);
        }

        if (pageData.exitpopupdata) {
          rxExitModalData.next(pageData.exitpopupdata);
        }

        rxBlocks.next(pageData.data);
        rxInitialBlocks.next(pageData.data);

        return {
          data,
          templateData,
        };
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    rxIsLoading.next('');
  }
};
