import { useState } from 'react';
import SwitchButton from '../SwitchButton';
import { getDomain } from 'utils/functions';

import s from './Preview.module.scss';

interface IProps {
  pageId: String;
}

const Preview = (props: IProps) => {
  const [previewWidth, setPreviewWidth] = useState('Responsive');

  const onSizeChanged = (value: string) => {
    setPreviewWidth(value);
  };

  const sizeClass = (value: string) => {
    if (value === 'Mobile') {
      return s.mobileSize;
    } else {
      return null;
    }
  };

  const { domain } = getDomain();

  return (
    <div
      className={s.container}
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/bg.png)`,
      }}
    >
      <iframe
        className={`${s.iframe} ${sizeClass(previewWidth)}`}
        src={`//${domain}/edit/l/${props.pageId}/optin`}
      ></iframe>
      <div className={s.sizeToolbar}>
        <SwitchButton value={previewWidth} onChange={onSizeChanged} />
      </div>
    </div>
  );
};

export default Preview;
