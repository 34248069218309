export const CREATE_TEMPLATE = {
  operationName: 'createSaasTemplate',
  query: `mutation createSaasTemplate($name: String!, $description: String, $pages: String!, $category: String!) {
          createSaasTemplate(name: $name, description: $description, pages: $pages, category: $category) {
                _id
                name
                description
                category
                createdAt
                updatedAt
                enabled
                pages {
                    _id
                    name
                    enabled
                    data
                    image
                }
           }
        }`,
};
