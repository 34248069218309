import React, { useState, useEffect } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';
import { getDomain } from 'utils/functions';
import Spinner from 'Components/Common/Spinner/Spinner';
import Button from '../Common/Button/Button';
import CustomStripeInput from './StripeInput/StripeInput';

import s from './StripePurchase.module.scss';

interface IProps {
  name: string;
  email: string;
  priceData?: { price: number; priceGap: string } | null;
}

const CheckoutForm = (props: IProps) => {
  const elements = useElements();
  const stripe = useStripe();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [termsChecked, setTermsChecked] = useState(false);
  useEffect(() => {
    setName(props.name);
  }, [props.name]);

  useEffect(() => {
    setEmail(props.email);
  }, [props.email]);
  const handleCheckBox = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsChecked(event.target.checked);
  };
  const handlePaymentSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    setErrorMessage('');

    if (email == '') {
      setErrorMessage('Please enter your email address');
      return;
    }

    if (name == '') {
      setErrorMessage('Please enter your name');
      return;
    }

    if (termsChecked == false) {
      setErrorMessage('Please agree to Refund & Trial Cancellation Policy');
      return;
    }

    setLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url:
          '//' +
          getDomain().domain +
          `/edit/signup?name=${name}&email=${email}`,
      },
    });
    if (error && error.message) {
      setLoading(false);
      setErrorMessage(error.message);
    }
    // console.log('error:', err);
  };

  return (
    <form onSubmit={handlePaymentSubmit}>
      <div className={s.paymentDetails}>
        <CustomStripeInput
          name="name"
          placeholder="Name"
          value={name}
          onChange={setName}
        />
        <CustomStripeInput
          name="email"
          placeholder="Email"
          value={email}
          onChange={setEmail}
        />
        <PaymentElement />
      </div>
      <div className={s.errorMessage}>{errorMessage}</div>
      {props.priceData && (
        <div className={s.priceInfoBlock}>
          <span>5 days free</span> <br />
          Then ${props.priceData.price} per{' '}
          {props.priceData.priceGap === 'annually' ? 'year' : 'month'}
        </div>
      )}

      <div style={{ padding: '10px', marginTop: '50px' }}>
        <input
          style={{ margin: '5px' }}
          type="checkbox"
          checked={termsChecked}
          onChange={handleCheckBox}
        />
        I agree to the{' '}
        <a href="https://cdn.autofunnel.ai/PageCraftLLCtos.html">
          {' '}
          Refund & Trial Cancellation Policy
        </a>
      </div>

      <div className={s.buttonBlock}>
        {!loading && (
          <div className={s.buttonBlock}>
            <Button type="submit">Complete</Button>
          </div>
        )}
        {loading && (
          <div className={s.spinnerBlock}>
            <Spinner size={36} />
          </div>
        )}
      </div>
    </form>
  );
};

export default CheckoutForm;
