import React, { useState, useEffect, useRef } from 'react';
import s from './ToolbarWidgetAiQuestions.module.scss';
import AiQuestionsSvg from 'Assets/icons/question.svg';
import plusSvg from 'Assets/plus.svg';
import previewSvg from 'Assets/navigationBarIcons/preview.svg';
import CloseSvg from 'Assets/icons/x.svg';
import { rxAiTemplate } from 'rx/rxState';
import { AiTemplateQuestion } from 'rx/actions/rxAiTemplateActions';
import clsx from 'clsx';
import { rxPageId } from '../../rx/rxState';
import { useObservable } from '../../utils/UseObservable';

const ToolbarWidgetAiQuestions = () => {
  const [open, setOpen] = useState(false);
  const [questions, setQuestions] = useState<AiTemplateQuestion[]>([]);
  const pageId = useObservable(rxPageId);

  useEffect(() => {
    const aiTemplateQuestionsSubscription = rxAiTemplate.subscribe({
      next: (v: any) => {
        setQuestions(v?.questions);
      },
    });
    return () => {
      aiTemplateQuestionsSubscription.unsubscribe();
    };
  }, []);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleAddQuestion = () => {
    setQuestions([
      ...(questions || []),
      {
        id: '',
        label: '',
        example: '',
      },
    ]);
  };

  const handlePreview = () => {
    const url = '/edit/ai-page/' + pageId;
    window.open(url, '_blank');
  };

  const handleRemoveQuestion = (index: number) => {
    let q = questions;
    q.splice(index, 1);
    setQuestions([...q]);
  };

  const handleQuestionChange = (
    value: string,
    index: number,
    component: 'id' | 'label' | 'example'
  ) => {
    const q = questions;
    q[index][component] = value;

    const newQuestions = [...q];
    setQuestions(newQuestions);
    const aiTemplate = rxAiTemplate.getValue();
    aiTemplate.questions = newQuestions;
    rxAiTemplate.next(aiTemplate);
  };

  return (
    <div className={s.container}>
      <button
        className={clsx(s.button, { [s.active]: open })}
        onClick={handleOpen}
      >
        <img src={AiQuestionsSvg} alt="AI Questions" />
        Questions
      </button>
      {open && (
        <div className={s.questionsContainer}>
          <div className={s.titleContainer}>
            <div className={s.title}> AI Template questions </div>

            <button className={s.addButton} onClick={handlePreview}>
              Preview
            </button>
          </div>

          {questions?.map((question, index) => (
            <div className={s.question} key={index}>
              <input
                className={s.questionName}
                placeholder="Name"
                value={question.id}
                onChange={(e) =>
                  handleQuestionChange(e.target.value, index, 'id')
                }
              />
              <input
                placeholder="Question"
                value={question.label}
                onChange={(e) =>
                  handleQuestionChange(e.target.value, index, 'label')
                }
              />
              <input
                placeholder="Answer Example"
                value={question.example}
                onChange={(e) =>
                  handleQuestionChange(e.target.value, index, 'example')
                }
              />
              <button
                className={s.removeQuestionButton}
                onClick={() => handleRemoveQuestion(index)}
              >
                <img src={CloseSvg} alt="x" />
              </button>
            </div>
          ))}

          <div className={s.footer}>
            <button className={s.addButton} onClick={handleAddQuestion}>
              <img src={plusSvg} alt="Add" /> Add
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ToolbarWidgetAiQuestions;
