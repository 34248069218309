export const base64ToFile = (
  base64String: string,
  filename: string,
  mimeType = 'image/png'
) => {
  const byteString = atob(base64String.split(',')[1]);

  const byteNumbers = new Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteNumbers[i] = byteString.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], { type: mimeType });

  return new File([blob], filename, { type: mimeType });
};
