import clsx from 'clsx';
import {
  useRef,
  ReactNode,
  useEffect,
  HTMLAttributes,
  DetailedHTMLProps,
} from 'react';
import { eventEmitter } from 'rx/rxState';
import Tooltip, { TooltipPlacement } from 'Components/Common/Tooltip/Tooltip';
import useElementClickedOutside from 'Hooks/useElementClickedOutside';

import './ToolbarManipulatorPopup.css';

interface IProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  placement?: TooltipPlacement;
  isPopupVisible: boolean;
  popupContent: ReactNode;
  onPopupClose: () => void;
}

const ToolbarManipulatorPopup = ({
  onClick,
  className,
  placement,
  popupContent,
  onPopupClose,
  isPopupVisible,
  ...rest
}: IProps) => {
  const { ref } = useElementClickedOutside(() => {
    if (showPopupRef.current) {
      onPopupClose();
    }
  });
  const showPopupRef = useRef(isPopupVisible);

  useEffect(() => {
    showPopupRef.current = isPopupVisible;
  }, [isPopupVisible]);

  useEffect(() => {
    const subscription = eventEmitter.subscribe({
      next: (e: any) => {
        if (e.type === 'view-on-scroll') {
          onPopupClose();
        }
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <Tooltip
      visible={isPopupVisible}
      placement={placement}
      overlayContent={
        isPopupVisible && (
          <div
            className={clsx('toolbar-manipulator-container', className)}
            ref={ref}
            onScroll={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
            onClick={(e) => {
              e.stopPropagation();
              if (onClick) onClick(e);
            }}
            {...rest}
          >
            {popupContent}
          </div>
        )
      }
    >
      <div className="toolbar-manipulator-content" />
    </Tooltip>
  );
};

export default ToolbarManipulatorPopup;
