export const CREATE_TEMPLATE_CATEGORY = {
  operationName: 'createSassCategory',
  query: `mutation createSassCategory($name: String!, $description: String!) {
        createSassCategory(name: $name, description: $description) {
            _id
            name
            description
            updatedAt
            createdAt
         }
      }`,
};
