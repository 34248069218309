import {
  TextareaHTMLAttributes,
  DetailedHTMLProps,
  ChangeEvent,
  LegacyRef,
  useState,
} from 'react';
import clsx from 'clsx';

import styles from './TextArea.module.scss';

interface IProps
  extends Omit<
    DetailedHTMLProps<
      TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    'onChange'
  > {
  theme?: 'light' | 'dark';
  height?: number;
  onChange: (newValue: string) => void;
  hasErrors?: boolean;
  maxLength?: number;
  textareaRef?: LegacyRef<HTMLTextAreaElement>;
  wrapperClassName?: string;
}

const TextArea = ({
  wrapperClassName,
  textareaRef,
  hasErrors,
  className,
  maxLength,
  onChange,
  height,
  theme = 'light',
  ...rest
}: IProps) => {
  const [error, setError] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (maxLength !== undefined && value.length > maxLength) {
      onChange(value.substring(0, maxLength));
      setError(true);
    } else {
      setError(false);
      onChange(value);
    }
  };

  return (
    <div className={clsx(styles.textareaWrapper, wrapperClassName)}>
      <textarea
        ref={textareaRef}
        onChange={handleChange}
        className={clsx(styles.textArea, className, styles[theme], {
          [styles.error]: hasErrors || error,
        })}
        {...rest}
        rows={height ? (height > 60 ? height / 22 - 1 : 1) : rest.rows}
      />
      {error && maxLength && (
        <div className={styles.errorText}>Maximum characters {maxLength}</div>
      )}
    </div>
  );
};

export default TextArea;
