import { ReactComponent as LogoIcon } from '../../Assets/bookleLogo.svg';
import { getDomain } from 'utils/functions';

import s from './logo.module.scss';

const Logo = () => {
  const redirectToHome = () => {
    const { domain } = getDomain();
    window.location.href = '//' + domain;
  };

  return (
    <div className={s.container}>
      <LogoIcon onClick={redirectToHome} className={s.cursor} />
    </div>
  );
};

export default Logo;
