import { useEffect, useState } from 'react';
import { eventEmitter, rxTemplateData } from 'rx/rxState';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import Input from 'UILib/Input/Input';
import Button from 'UILib/Button/Button';
import Dropdown from 'UILib/Dropdown/Dropdown';
import PopUpWrapper from 'Components/Popups/PopupWrapper';
import CreateTemplateCategoryModal from '../CreateTemplateCategoryModal/CreateTemplateCategoryModal';

import styles from './SaveAsTemplateModal.module.scss';

interface IProps {
  onClose: () => void;
  isNew?: boolean;
  templateValues?: IValues;
}

interface ITemplateCategory {
  _id: string;
  name: string;
  description?: string;
}

interface IValues {
  name: string;
  category: string;
}

const SavaAsTemplateModal = ({ onClose, isNew, templateValues }: IProps) => {
  const [categories, setCategories] = useState<ITemplateCategory[]>([]);
  const [isCreateCategoryOpen, setIsTemplateCategoryOpen] =
    useState<boolean>(false);
  const [values, setValues] = useState<IValues>({
    name: '',
    category: '',
  });

  useEffect(() => {
    setValues(
      templateValues ?? {
        name: '',
        category: '',
      }
    );
  }, [templateValues]);

  useEffect(() => {
    const stopPropagation = (e: KeyboardEvent) => {
      e.stopPropagation();
    };

    document.addEventListener('keydown', stopPropagation);

    getTemplateCategories();

    return () => {
      document.removeEventListener('keydown', stopPropagation);
    };
  }, []);

  const getTemplateCategories = async () => {
    const templateCategories = await graphQlCall({
      queryTemplateObject: queries.GET_TEMPLATE_CATEGORIES,
      headerType: 'USER-AUTH',
    });

    setCategories(templateCategories.categories);
  };

  const handleNewCategory = (category: ITemplateCategory) => {
    setCategories([category, ...categories]);
    setValues({ ...values, category: category._id });
  };

  const onSelectChange = (value: string) => {
    if (value === 'add') {
      setIsTemplateCategoryOpen(true);
    } else {
      setValues({ ...values, category: value });
    }
  };

  const handleClick = () => {
    if (!!values.category && !!values.category && !!values.name) {
      rxTemplateData.next(values);
      if (isNew) {
        eventEmitter.next({
          type: 'create-template',
        });
      } else {
        eventEmitter.next({
          type: 'update-template',
        });
      }
      onClose();
    }
  };

  return (
    <>
      <PopUpWrapper
        onClose={onClose}
        containerClassName={styles.container}
        className={styles.modalClassName}
      >
        <div className={styles.content}>
          <div className={styles.title}>
            {isNew ? 'New Template' : 'Save Template'}
          </div>
          <div className={styles.subtitle}>
            {isNew
              ? 'Create new Template and share with your customers'
              : 'Save the new template and select a group for it.'}
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.label}>
              Template Name <sup className={styles.asterisk}>*</sup>
            </div>
            <Input
              value={values.name}
              border="stroke"
              height={40}
              className={styles.input}
              onChange={(e) => setValues({ ...values, name: e.target.value })}
            />
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.label}>
              Group <sup className={styles.asterisk}>*</sup>
            </div>
            <Dropdown
              options={[
                ...(categories?.map((e) => ({ label: e.name, value: e._id })) ||
                  []),
                { label: '+ Add', value: 'add' },
              ]}
              onChange={onSelectChange}
              label="Select Group"
              value={categories.find((e) => e._id === values.category)?.name}
              type="stroke"
            />
          </div>
          <Button className={styles.button} onClick={handleClick}>
            Save
          </Button>
        </div>
      </PopUpWrapper>
      <CreateTemplateCategoryModal
        open={isCreateCategoryOpen}
        onClose={() => setIsTemplateCategoryOpen(false)}
        handleNewCategory={handleNewCategory}
      />
    </>
  );
};

export default SavaAsTemplateModal;
