import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Block, BlockStyle } from './Block';
import StoreController from '../../StoreController';
import Spinner from '../../Components/Common/Spinner/Spinner';
import { api, isAdmin } from '../../utils/api';
import { API_ZAPIER, PAGECRAFT_API_URL } from '../../Constants';
import {
  rxProjectId,
  rxPageId,
  rxProducts,
  eventEmitter,
} from '../../rx/rxState';
import { graphQlCall } from 'graphql/utils';
import { sendUserEvent } from 'rx/actions/rxUserEvent';
import QUERIES from 'graphql/queries';

const ButtonStyle = styled.div`
  margin: auto;
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.borderRadius};
  width: 100%;
  position: relative;
`;

const LoadingStyle = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export class BlockButtonProxy extends React.Component {
  constructor(props) {
    super(props);
    this.targetType = props.targetType;
    this.state = {
      loading: false,
      error: null,
    };
  }
  onChange(editorState) {
    this.setState({ content: editorState });
    this.props.onChange(editorState);
  }

  componentDidMount() {
    this.props.reference.current.style.cssText = this.props.styleText();
  }
  componentDidUpdate(prevProps) {
    // if (prevProps.styleText !== this.props.styleText) {
    this.props.reference.current.style.cssText = this.props.styleText();
    // }
  }
  async onFormCallback(status, error, mode, amount) {
    if (status && !error) {
      if (mode === 'stripe') {
        let formData = localStorage.getItem('key')
          ? JSON.parse(localStorage.getItem('key'))
          : [];
        this.sendCustomerData(formData, (status, error) =>
          this.onFormCallback(status, error)
        );

        await sendUserEvent({
          type: 'SALE',
          pageId: rxPageId.getValue(),
          value: amount * 100,
        }); //Value is in cents
      } else {
        await sendUserEvent({ type: 'LEAD', pageId: rxPageId.getValue() });
      }
      this.nextPage();
    }
    if (error) {
      this.setState({ loading: false });
    }
  }

  async nextPage() {
    if (this.props.destination.value == 'popup') {
      eventEmitter.next({
        type: 'switch-view-exit-modal',
      });
      this.setState({ loading: false });
    } else if (this.props.destination.value == 'custom') {
      if (this.props.url.value) {
        window.location += this.props.url.value;

        // this.navigate();
      }
      this.setState({ loading: false });
    } else if (this.props.destination.value == 'URL') {
      if (this.props.url.value) {
        let url = this.props.url.value;
        if (
          this.props.url.value.indexOf('https://') === -1 &&
          this.props.url.value.indexOf('http://') === -1
        ) {
          url = 'https://' + this.props.url.value;
        }

        this.navigate(url);
      }
      this.setState({ loading: false });
    } else {
      const result = await graphQlCall({
        queryTemplateObject: QUERIES.GET_NEXT_PAGE_QUERY,
        values: {
          pageId: rxPageId.getValue(),
          funnelId: rxProjectId.getValue(),
        },
      });
      let url = window.location.href;
      if (result.next) {
        let sliceAmount = -2;

        let components = url.split('/');
        if (!components[components.length - 1].includes('optin')) {
          //TODO remove this dirty hardcoded workaround
          sliceAmount = -1;
        }
        let destination =
          url.split('/').slice(0, sliceAmount).join('/') +
          '/' +
          result.next +
          '/optin';
        destination += window.location.search; //pass all URL querry params to next page ( needs for UTM variables and such )
        this.navigate(destination);
      } else {
        this.setState({ loading: false });
      }
    }
  }

  navigate(url) {
    if (this.targetType.value === 'New Tab') {
      window.open(url, '_blank');
    } else if (this.targetType.value === 'Same Page') {
      window.open(url, '_top');
    } else {
      window.open(url);
    }
    //Extra Delay to allow borwser perform redirect if needed.
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  }
  getFormValue(key, formData) {
    for (const data of formData) {
      if (data.type === key) {
        return data.value;
      }
    }
  }
  async onClick() {
    if (StoreController.instance().liveMode === false) {
      return;
    }
    if (this.state.loading) {
      return;
    }
    this.setState({ error: null });

    let pass = true;
    let formData = [];
    let rootSection = this.props.view.getRootSectionFor(this.props.block);
    let forms = rootSection.getBlocksByType('Form');
    for (let form of forms) {
      if (form.isVisible === false) {
        continue;
      }
      let data = form.verifyInput();
      if (data === false) {
        pass = false;
      } else {
        if (form.formType !== 'Checkbox') {
          formData.push(data);
        }
      }
    }
    if (!this.props.formValidation) {
      pass = true;
    }

    let stripe = rootSection.getBlocksByType('Stripe');
    for (let form of stripe) {
      if (!form.verifyInput() && pass) {
        pass = false;
      }
    }

    if (!pass) {
      return;
    }

    if (formData.length > 0) {
      localStorage.setItem('key', JSON.stringify(formData));
    }
    if (stripe.length > 0) {
      this.setState({ loading: true });
      stripe[0].createPaymentIntent(formData, async (status, error) => {
        if (status === false) {
          this.onFormCallback(status, error, 'stripe');
          if (error) {
            this.setState({ error: error });
          }
          return;
        }

        const dict = {};
        for (let item of formData) {
          dict[item.type] = item.value;
        }
        const request = {
          data: {
            name: dict.FirstName || dict.Name,
            email: dict.Email,
            phone: dict.PhoneNumber,
          },
          funnelId: rxProjectId.getValue(),
          pageId: rxPageId.getValue(),
          type: 'CREATE_CUSTOMER',
        };
        const response = await api(
          `${PAGECRAFT_API_URL}/zapier/createSale`,
          'POST',
          request
        );
        if (response.error) {
          console.log(response.error);
          return;
        }
        this.onFormCallback(status, error, 'stripe', stripe[0].amount);
      });
    } else if (formData.length > 0) {
      this.setState({ loading: true });

      if (this.props.destination.value === 'BookleFree') {
        this.sendCustomerData(formData, async (status, error) => {
          const email = this.getFormValue('Email', formData);
          if (email) {
            const queryParams = new URLSearchParams(window.location.search);
            let values = { email: email };
            if (queryParams.get('rid')) {
              values['referalId'] = queryParams.get('rid');
            }
            try {
              const result = await graphQlCall({
                queryTemplateObject: QUERIES.START_FREE_SUBSCRIPTION,
                values: values,
              });
              this.setState({ loading: false });
              window.open(result.link + '?app=bookle', '_top');
            } catch (error) {
              this.setState({ loading: false });
              const errorMessage = error.message.split(':')[1]; //TODO: need to find a better way to extract proper messages from Graph QL errors
              console.error('error:', errorMessage);
              this.setState({ error: errorMessage });
            }
          }
        });
      } else {
        this.sendCustomerData(formData, (status, error) =>
          this.onFormCallback(status, error)
        );
      }
    } else if (this.props.destination.value === 'upsell') {
      this.setState({ loading: true });
      let formData = localStorage.getItem('key')
        ? JSON.parse(localStorage.getItem('key'))
        : [];
      let dict = {};
      for (const d of formData) {
        dict[d.type] = d.value;
      }
      this.sendUpsellData(
        {
          ...dict,
          priceID: this.props.priceId,
          productID: this.props.productId || this.props.block.userProduct.id,
        },
        (status, error) => this.onFormCallback(status, error, 'stripe')
      );
    } else {
      this.setState({ loading: true });
      this.nextPage();
    }
  }

  async sendUpsellData(data, callback) {
    const funnelId = rxProjectId.getValue();
    const payload = {
      projectId: funnelId,
      lm_data: window.lm_data,
      ...data,
    };

    if (this.selectedUserProductId && this.selectedUserProductId.value) {
      payload.productID = this.selectedUserProductId.value;
      payload.priceID = 'user price';
    }

    if (payload['First Name']) {
      payload.FirstName = payload['First Name'];
      delete payload['First Name'];
    }
    if (payload['Last Name']) {
      payload.LastName = payload['Last Name'];
      delete payload['Last Name'];
    }

    const customerKey = `${funnelId}|customerId`;
    const customerId = localStorage.getItem(customerKey);
    if (customerId) {
      payload.stripeCustomerId = customerId;
    }

    try {
      await graphQlCall({
        queryTemplateObject: QUERIES.CREATE_UPSELL_PAYMENT_INTENT2,
        values: payload,
      });

      localStorage.removeItem(customerKey);

      const request = {
        data: {
          name: payload.FirstName || payload.Name,
          email: payload.Email,
          phone: payload.PhoneNumber,
        },
        funnelId,
        pageId: rxPageId.getValue(),
        type: 'CREATE_CUSTOMER',
      };

      const response = await api(
        `${PAGECRAFT_API_URL}/zapier/createUpsell`,
        'POST',
        request
      );
      if (response.error) {
        console.log(response.error);
        callback(false, response.error);
        return;
      }

      callback(true);
    } catch (err) {
      callback(false, err);
    }
  }

  sendCustomerData(data, callback) {
    const dict = {};
    for (let item of data) {
      dict[item.type] = item.value;
    }
    const request = {
      data: {
        firstName: dict.FirstName || dict.Name,
        lastName: dict.LastName,
        email: dict.Email,
        phone: dict.PhoneNumber,
      },
      funnelId: rxProjectId.getValue(),
      pageId: rxPageId.getValue(),
      type: 'CREATE_CUSTOMER',
    };
    api(`${PAGECRAFT_API_URL}/zapier/createLead`, 'POST', request)
      .then((result) => {
        if (result.error) {
          callback(false, result.error);
        } else {
          callback(true);
        }
      })
      .catch((error) => {
        console.error('error:', error);
        callback(false, error);
      });
  }

  render() {
    let content = (
      <ButtonStyle
        borderRadius={this.props.borderRadius}
        backgroundColor={this.props.backgroundColor}
      >
        {this.state.loading ? (
          <LoadingStyle>
            <Spinner size={40} />
          </LoadingStyle>
        ) : null}

        <div
          style={{
            opacity: this.state.loading ? '0' : '1',
            margin: '15px auto 15px auto',
          }}
        >
          {this.props.children}
          {this.state.error && (
            <div
              style={{ position: 'absolute', width: '100%', display: 'flex' }}
            >
              <div
                style={{
                  textAlign: 'center',
                  margin: 'auto',
                  background: '#f26262',
                  padding: '0px 5px',
                  borderRadius: '8px',
                  color: 'white',
                  fontWeight: '800',
                }}
              >
                {this.state.error}
              </div>
            </div>
          )}
        </div>
      </ButtonStyle>
    );

    let output = (
      <BlockStyle
        id={this.props.id}
        key={this.props.id}
        onClick={this.onClick.bind(this)}
        className={this.props.className}
        // width={this.props.width}
        ref={this.props.reference}
      >
        {content}
      </BlockStyle>
    );

    return output;
  }
}

export class BlockButton extends Block {
  constructor(props) {
    super(props);

    this.type = 'Button';
    this.userProduct = undefined;
    this.className = 's-button-solid';

    let attr = {
      id: 'color',
      displayName: 'Color',
      value: '#49BAFF',
      type: 'AttributeColor',
    };
    this.addAttribute(attr);
    attr = {
      id: 'borderRadius',
      displayName: 'Border radius',
      value: '30px',
      type: 'Dropdown',
      options: [
        {
          label: 'Completely round',
          value: '30px',
        },
        {
          label: 'Round corners',
          value: '10px',
        },
        {
          label: 'Square',
          value: '0',
        },
      ],
    };
    this.addAttribute(attr);
    attr = {
      id: 'destination',
      displayName: 'Destination',
      value: 'Next Page',
      type: 'Dropdown',
      options: [
        {
          label: 'Next Page',
          value: 'Next Page',
        },
        {
          label: 'Popup',
          value: 'popup',
        },
        {
          label: '1-Click Upsell',
          value: 'upsell',
        },
        {
          label: 'URL',
          value: 'URL',
        },
      ],
    };
    this.addAttribute({
      id: 'selectedUserProductId',
      value: '',
    });

    //Adding special custom action for Autofunnel to start Free Subscription
    if (isAdmin()) {
      attr.options.push({ label: 'Bookle Free', value: 'BookleFree' });
    }
    this.addAttribute(attr);

    attr = {
      id: 'targetType',
      displayName: 'Open As',
      value: 'Same Page',
      type: 'Dropdown',
      options: [
        {
          label: 'Same Page',
          value: 'Same Page',
        },
        {
          label: 'New Tab',
          value: 'New Tab',
        },
      ],
    };
    this.addAttribute(attr);

    attr = {
      id: 'url',
      displayName: '',
      placeholder: 'Paste Link',
      value: '',
      width: 200,
      prefix: '' /* was 'https://'  */,
      type: 'String',
      visible: false,
    };
    this.addAttribute(attr);

    attr = {
      id: 'formValidation',
      displayName: 'Forms Validation',
      value: false,
      type: 'AttributeBool',
    };
    this.addAttribute(attr);

    let attribute = {
      id: 'product',
      displayName: 'Product',
      value: '',
      type: 'Dropdown',
      options: [],
    };
    this.addAttribute(attribute);

    attribute = {
      id: 'price',
      displayName: 'Price',
      value: '',
      type: 'Dropdown',
      options: [],
    };
    this.addAttribute(attribute);

    rxProducts.subscribe({
      next: (v) => this.updateProducts(v),
    });

    this.updateProducts(rxProducts.value);
  }

  updateProducts(products) {
    let productOptions = [];
    for (let product of products) {
      let id;
      if (product.id) {
        id = product.id;
      } else {
        id = product.name;
      }
      productOptions.push({
        label: product.name,
        value: id,
        type: product.type,
      });
    }
    this.product.options = productOptions;
  }

  update() {
    this.url.visible = false;
    this.product.visible = false;
    this.price.visible = false;

    if (
      this.destination.value === 'URL' ||
      this.destination.value === 'custom'
    ) {
      this.url.visible = true;
    }
    if (this.destination.value === 'upsell' && this.userProduct === undefined) {
      this.product.visible = true;

      let priceOptions = [];
      for (const product of rxProducts.value) {
        let id;
        if (product.id) {
          id = product.id;
        } else {
          id = product.name;
        }
        if (id === this.product.value) {
          if (!product.prices) continue;

          if (product.type === 'USER' || product.type === 'FUNNEL') {
            this.price.value = product.prices[0].id;
            this.price.visible = false;
          } else {
            for (const price of product.prices) {
              priceOptions.push({
                label: price.unit_amount / 100 + ' ' + price.currency,
                value: price.id,
              });
            }
            this.price.visible = true;
          }
          break;
        }
      }
      this.price.options = priceOptions;
    }

    super.update();
  }

  onAttributeChanged(attr, newValue) {
    if (attr === 'product') {
      this.price.value = '';
    }
    if (attr === 'selectedUserProductId') {
      this.userProduct.id = newValue;
    }
  }

  setEditing(value) {
    let textBlock = this.children[0];
    if (value) {
      this.view.overlay.selectBlock(textBlock);
    }
    textBlock.setEditing(value);
  }

  style() {
    let output = super.style();

    if (StoreController.instance().liveMode) {
      output += '; cursor: pointer;';
    }
    return output;
  }

  pack() {
    let data = super.pack();
    if (this.userProduct !== undefined) {
      data['userProduct'] = this.userProduct;
    }
    return data;
  }

  unpack(data) {
    super.unpack(data);
    this.userProduct = data['userProduct'];

    if (this.userProduct !== undefined) {
      this.selectedUserProductId.value = this.userProduct.id;

      this.price.required = false;
      this.price.visible = false;

      this.product.required = false;
      this.product.visible = false;
    }

    //temp: workaround related to issue where text on buttons can't be re-selected properly
    for (let child of this.children) {
      child.isDraggable = true;
    }
  }
  renderView() {
    return (
      <Fragment key={this.id}>
        {this.isVisible ? (
          <>
            {/* {this.isDragging ? this.getPlaceholder()
                            : */}
            <BlockButtonProxy
              id={this.id}
              key={this.id}
              view={this.view}
              reference={this.ref}
              ref={this.proxyRef}
              width={this.worldRenderBRect ? this.worldRenderBRect.width : 0}
              styleText={this.style}
              targetType={this.targetType}
              destination={this.destination}
              productId={this.product.value}
              priceId={this.price.value}
              formValidation={this.formValidation.value}
              block={this}
              url={this.url}
              className={this.className}
              backgroundColor={this.color.value}
              borderRadius={this.borderRadius.value}
            >
              {this.children.map((child) => {
                return child.renderView();
              })}
            </BlockButtonProxy>
            {/* } */}
          </>
        ) : null}
      </Fragment>
    );
  }
}
