import React, { Fragment } from 'react';
import { Block, BlockStyle } from './Block';
import BookCoverPreview from './BookCoverPreview/BookCoverPreview';

export class BlockBookCoverProxy extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.reference.current.style.cssText = this.props.styleText();
  }

  componentDidUpdate(prevProps) {
    this.props.reference.current.style.cssText = this.props.styleText();
  }

  render() {
    return (
      <div ref={this.props.reference}>
        <BookCoverPreview
          template={this.props.previewStyle}
          image={this.props.imageUrl}
        />
      </div>
    );
  }
}

export class BlockBookCoverPreview extends Block {
  constructor(props) {
    super(props);
    this.type = 'BookCoverPreview';

    let attr = {
      id: 'previewStyle',
      displayName: 'Preview Style',
      value: 'bk3',
      type: 'AttributeDropdown',
      options: [
        {
          label: 'Single In Perspective',
          value: 'bk3',
        },
        {
          label: 'Single Flat',
          value: 'bk4',
        },
        {
          label: 'Double In Perspective',
          value: 'bk5',
        },
      ],
    };

    this.addAttribute(attr);

    attr = {
      id: 'imageUrl',
      displayName: 'Image',
      visible: true,
      type: 'AttributeImage',
    };
    this.addAttribute(attr);
  }

  renderView() {
    return (
      <Fragment key={this.id}>
        {this.isVisible ? (
          <BlockBookCoverProxy
            reference={this.ref}
            ref={this.proxyRef}
            styleText={this.style}
            id={this.id}
            imageUrl={this.imageUrl.value}
            previewStyle={this.previewStyle.value}
          >
            {this.children.map((child) => {
              return child.renderView();
            })}
          </BlockBookCoverProxy>
        ) : null}
      </Fragment>
    );
  }
}
