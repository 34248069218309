export const GET_TEMPLATE_CATEGORIES = {
  operationName: 'getOwnSaasTemplatesAndCategories',
  query: `query getOwnSaasTemplatesAndCategories {
        getOwnSaasTemplatesAndCategories {
            categories {
                _id
                name
                description
                createdAt
                updatedAt
            }
        }
      }`,
};
