import { ReactNode, PropsWithChildren, ButtonHTMLAttributes } from 'react';
import clsx from 'clsx';

import s from './Button.module.scss';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  width?: number;
  height?: number;
  appearance?: 'solid' | 'stroke' | 'highlighted';
  theme?: 'dark' | 'light';
  prefixIcon?: ReactNode;
  postfixIcon?: ReactNode;
}

const Button: React.FC<PropsWithChildren<Props>> = ({
  style,
  className,
  children,
  width = 158,
  height = 50,
  prefixIcon,
  postfixIcon,
  appearance = 'highlighted',
  theme = 'light',
  ...rest
}) => {
  return (
    <button
      className={clsx(
        s.buttonContainer,
        s[appearance],
        s[theme],
        { [s.disabled]: rest.disabled },
        className
      )}
      style={{ width, height, ...style }}
      {...rest}
    >
      {prefixIcon}
      {children && <span className={s.text}>{children}</span>}
      {postfixIcon}
    </button>
  );
};

export default Button;
