import { useState, useEffect, useRef, useMemo } from 'react';
import RerollInput from 'Components/AIRerollImage/RerollInput/RerollInput';
import RerollSelect from 'Components/AIRerollImage/RerollSelect/RerollSelect';
import { ReactComponent as Premium } from 'Assets/Premium.svg';
import { generateAiImageBySocket } from 'utils/socket';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import { initiateBadWordsFilter } from 'Utils';
import { useObservable } from 'utils/UseObservable';
import { IUserDetails } from 'types/user';
import { getUserId } from 'utils/api';
import {
  rxCurrentUser,
  rxBadContentAlert,
  rxUpdatePlanPopup,
} from 'rx/rxState';

import styles from './RerollComponent.module.scss';

interface IProps {
  onChange: (url: string) => void;
  onSubmit: (url: string) => void;
  onStart: () => void;
  onCancel: () => void;
}

const RerollComponent = (props: IProps) => {
  const [inputOpen, setInputOpen] = useState(true);
  const [imagesUrls, setImagesUrls] = useState<string[]>([]);
  const [selectOpen, setSelectOpen] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState('');
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);
  const [intervalF, setIntervalF] = useState(0);
  const [imageConfirmed, setImageConfirmed] = useState(false);
  const [aiRequestId, setAiRequestId] = useState<null | string>(null);

  const currentUser = useObservable<IUserDetails>(rxCurrentUser);

  const percentRef = useRef(0);
  const aiUrlsRef = useRef<string[]>([]);
  const selectedRef = useRef<string | null>(null);
  const badWordFilter = initiateBadWordsFilter();

  useEffect(() => {
    props.onStart();
  }, []);

  const handleStartGeneration = async () => {
    if (!text || text.trim().length === 0) {
      return;
    }
    if (!hasCorrectPlan) {
      rxUpdatePlanPopup.next({
        open: true,
        message: 'Upgrade your plan to access this feature',
      });
      return;
    }

    setLoading(true);
    imitateProgressBar();

    const imageUrls: string[] = await getNewImageUrlsWithAI(text);
    aiUrlsRef.current = imageUrls;
    setImagesUrls(imageUrls);
    setSelectedUrl(imageUrls[0]);
    setInputOpen(false);
    setSelectOpen(true);
    setLoading(false);

    props.onChange(imageUrls[0]);
  };

  const getNewImageUrlsWithAI = async (text: string): Promise<string[]> => {
    // const hasBadWords = badWordFilter.isProfane(text);
    // if (hasBadWords) {
    //   rxBadContentAlert.next(true);
    //   return [];
    // }

    const currentUrl = window.location.href;

    const ratio = currentUrl.includes('book-editor') ? '6:4' : '1:1';
    const payload: any = {
      text,
      imageCount: 4,
      ratio,
    };

    const userId = getUserId();
    if (userId) {
      payload.userId = userId;
    }

    if (currentUrl.includes('/edit/edit/')) {
      const urlParts = currentUrl.split('/');
      const pageId = urlParts[urlParts.length - 2];
      payload.pageId = pageId;
    }

    const response: any = await generateAiImageBySocket({
      payload,
      callback: (response: any) =>
        handleAiCallback(response.id, response.error),
    });

    return response.imageUrls as string[];
  };

  const handleAiCallback = (id: string, error?: string) => {
    if (error) {
      console.error('IMAGE GENERATION ERROR', error);
      setLoading(false);
      percentRef.current = 0;
      rxBadContentAlert.next(true);
    } else {
      setAiRequestId(id);
    }
  };

  const nextImage = () => {
    const currentIndex = imagesUrls.findIndex((url) => url === selectedUrl);
    if (currentIndex === undefined || currentIndex === imagesUrls.length) {
      return;
    }
    imagesUrls.forEach((url, index) => {
      if (currentIndex + 1 === index) {
        setSelectedUrl(url);
        props.onChange(url);
      }
    });
  };

  const previousImage = () => {
    const currentIndex = imagesUrls.findIndex((url) => url === selectedUrl);
    if (!currentIndex) {
      return;
    }
    imagesUrls.forEach((url, index) => {
      if (currentIndex - 1 === index) {
        setSelectedUrl(url);
        props.onChange(url);
      }
    });
  };

  const handleSubmit = () => {
    setSelectOpen(false);
    setImageConfirmed(true);
    props.onSubmit(selectedUrl);
    selectedRef.current = selectedUrl;
  };

  const handleEdit = () => {
    setSelectOpen(false);
    setInputOpen(true);
    setLoading(false);
  };

  const handleRegenerate = () => {
    setSelectOpen(false);
    setInputOpen(true);
    setLoading(true);
    handleStartGeneration();
  };

  const handleCancel = async () => {
    setLoading(false);
    percentRef.current = 0;
    if (aiRequestId) {
      await graphQlCall({
        queryTemplateObject: queries.CANCEL_IMAGE_GENERATION,
        headerType: 'USER-AUTH',
        values: {
          id: aiRequestId,
        },
      });
    } else {
      console.log('NO AI REQUEST ID');
    }
  };

  useEffect(() => {
    percentRef.current += 1;
    setProgressPercent(percentRef.current);
  }, [intervalF]);

  useEffect(() => {
    return () => {
      const allUrls = [...aiUrlsRef.current];
      if (!allUrls.length) {
        return;
      }
      const unusedUrls = selectedRef.current
        ? allUrls.filter((url) => url !== selectedRef.current)
        : allUrls;
      // removeUnusedFiles(unusedUrls);
    };
  }, []);

  const hasCorrectPlan = useMemo(() => {
    const legacyAccessMode = true;
      
    if (legacyAccessMode) {
      return true;
    } else {
      return currentUser?.scopes?.includes('BOOKLE_COVER_EDITOR');
    }
  }, [currentUser]);

  const imitateProgressBar = () => {
    percentRef.current = 0;
    setIntervalF(0);
    const interval = setInterval(() => {
      if (percentRef.current < 100) {
        setIntervalF(percentRef.current + 1);
      } else {
        clearInterval(interval);
      }
    }, 200);
  };

  const handleCancelSelect = () => {
    props.onCancel();
    setSelectOpen(false);
    setInputOpen(true);
  };

  const removeUnusedFiles = async (urls: string[]) => {
    await graphQlCall({
      queryTemplateObject: queries.REMOVE_FILES_FROM_S3,
      values: {
        urls,
      },
    });
  };

  return (
    <div className={styles.container}>
      {!hasCorrectPlan && (
        <Premium className={styles.premiumIcon} width={10} height={10} />
      )}
      {inputOpen && (
        <RerollInput
          onSubmit={handleStartGeneration}
          text={text}
          onTextChange={setText}
          loading={loading}
          onCancel={handleCancel}
          percent={progressPercent}
        />
      )}
      {selectOpen && (
        <RerollSelect
          imageUrls={imagesUrls}
          onEdit={handleEdit}
          onNext={nextImage}
          onPrev={previousImage}
          onRegenerate={handleRegenerate}
          onSelect={handleSubmit}
          selectedUrl={selectedUrl}
          onCancel={handleCancelSelect}
        />
      )}
    </div>
  );
};

export default RerollComponent;
