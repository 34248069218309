import { useState } from 'react';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import Input from 'UILib/Input/Input';
import Button from 'UILib/Button/Button';
import TextArea from 'UILib/TextArea/TextArea';
import PopUpWrapper from 'Components/Popups/PopupWrapper';
import Loader from '../Loader/Loader';

import styles from './CreateTemplateCategoryModal.module.scss';

interface IProps {
  onClose: () => void;
  open: boolean;
  handleNewCategory: (category: {
    _id: string;
    name: string;
    description?: string;
  }) => void;
}

const CreateTemplateCategoryModal = ({
  open,
  onClose,
  handleNewCategory,
}: IProps) => {
  const [value, setValue] = useState<{ name: string; description: string }>({
    name: '',
    description: '',
  });
  const [loading, setLoading] = useState<boolean>(false);

  const createNewCategory = async () => {
    try {
      if (!!value?.name.length && !!value?.description?.length && !loading) {
        setLoading(true);
        const newCategory = await graphQlCall({
          queryTemplateObject: queries.CREATE_TEMPLATE_CATEGORY,
          values: value,
          headerType: 'USER-AUTH',
        });

        handleNewCategory(newCategory);
        handleClose();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setValue({ name: '', description: '' });
    onClose();
  };

  if (!open) return null;

  return (
    <PopUpWrapper
      onClose={handleClose}
      containerClassName={styles.container}
      className={styles.modalClassName}
    >
      <div className={styles.content}>
        <div className={styles.title}>Create Template Category</div>

        <div className={styles.inputContainer}>
          <div className={styles.label}>
            Category Name <span className={styles.asterisk}>*</span>
          </div>
          <Input
            value={value.name}
            border="stroke"
            height={40}
            className={styles.input}
            onChange={(e) => setValue({ ...value, name: e.target.value })}
          />
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.label}>
            Category Description <span className={styles.asterisk}>*</span>
          </div>
          <TextArea
            onChange={(text) => setValue({ ...value, description: text })}
            value={value.description}
          />
        </div>
        <Button
          className={styles.button}
          onClick={createNewCategory}
          disabled={loading}
        >
          {loading ? <Loader size={16} color="#ffffff" /> : 'Create'}
        </Button>
      </div>
    </PopUpWrapper>
  );
};

export default CreateTemplateCategoryModal;
